import { useState, useMemo, useContext } from 'react';
import { validationSchema } from './schema';
import axiosInstance from 'utils/axiosInstance';
import { useFormik, FormikProvider } from 'formik';
import { PlansContext } from 'context/PlansContext';
import { ToastContext } from 'context/ToastContext';
import { CouponsContext } from 'context/CouponsContext';
import { onClickCopy, onClickShare } from 'helpers/table';
import { getTableDataActiveCoupons } from 'helpers/getTablesData';
import { getModifiedActiveCouponsList, getDataWithValidPlan } from 'helpers/modifiedData';

import Button from 'components/Button';
import TableFilters from 'components/Table/TableFilters';
import ErrorMessage from 'components/Table/ErrorMessage';

import DeleteIcon from 'assets/icons/delete.svg';

import styles from './styles.module.scss';

const ActiveCoupons = () => {
  const { plans, plansOptions } = useContext(PlansContext);
  const { toastError, toastSuccess } = useContext(ToastContext);
  const [checkedCoupons, setCheckedCoupons] = useState([]);
  const { couponsList, loadFlag, setLoadFlag } = useContext(CouponsContext);

  const updateActiveCoupons = async (data) => {
    const validData = getDataWithValidPlan(data, 'minLimitPlanId');
		try {
			const resp = await axiosInstance.put(`/update-coupons`, validData);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Active coupons table has been updated`);
      }
		} catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }
			console.log('err', error)
		}
	};

  const deleteCoupons = async () => {
		try {
			const resp = await axiosInstance.post(`/delete-coupons`, checkedCoupons);

      if (resp) {
        setCheckedCoupons([]);
        setLoadFlag(!loadFlag);
        toastSuccess(`Coupons have been deleted`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const filtersItems = [
    { icon: DeleteIcon, text: 'Delete', onClick: deleteCoupons },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: couponsList || [],
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updateActiveCoupons(values);
    },
  });

  const { values, errors, isValid, dirty, handleSubmit, handleChange, setFieldValue } = formik;

  const handleChangeChecked = (rowId) => {
    let checked = [...checkedCoupons, rowId];

    if (checkedCoupons.includes(rowId)) {
      checked = checked.filter(day => day !== rowId);
    } 
    setCheckedCoupons(checked);
  }

  const tableData = getTableDataActiveCoupons({
    values,
    couponsList,
    onClickCopy,
    plansOptions,
    handleChange,
    onClickShare,
    setFieldValue,
    handleChangeChecked,
  })

  const tableRows = ({ row, rowIndex }) =>
    tableData.map((item, index) => (
      item.component
        ? <td key={item.title + index + 'component'}>{item.component({rowId: row._id, rowIndex})}</td>
        : <td key={item.title + index + 'key'}>{row[item.key]}</td>
    ));

  const modifiedCouponsList = useMemo(() => getModifiedActiveCouponsList(couponsList, plans), [couponsList, plans]);

  return (
    <FormikProvider value={formik}>
      <div className={styles.table_wrapper}>
        
        <TableFilters data={filtersItems} />
        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                {tableData.map((header, index) => (<th key={header.title + index}>{header.title}</th>))}
              </tr>
            </thead>
            <tbody>
              {modifiedCouponsList?.map((row, index) => (
                <tr key={index}>
                  {tableRows({row, rowIndex: index})}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ErrorMessage errors={errors} />
        <div className={styles.button_wrapper}>
          <Button
            text='Save'
            type='submit'
            onClick={handleSubmit}
            disabled={!isValid || !dirty}
          />
        </div>
        
      </div>
    </FormikProvider>
  );
}

export default ActiveCoupons;

import { useMemo, useState, useContext } from 'react';
import { validationSchema } from './schema';
import axiosInstance from 'utils/axiosInstance';
import { useFormik, FormikProvider } from 'formik';
import { PlansContext } from 'context/PlansContext';
import { ToastContext } from 'context/ToastContext';
import { AllUsersContext } from 'context/AllUsersContext';
import { getTableDataUserList } from 'helpers/getTablesData';
import { getModifiedUsersList, getDataWithValidPlan } from 'helpers/modifiedData';

import Button from 'components/Button';
import TableFilters from 'components/Table/TableFilters';
import ErrorMessage from 'components/Table/ErrorMessage';

import BlockIcon from 'assets/icons/block.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import UnblockIcon from 'assets/icons/unblock.svg';

import styles from './styles.module.scss';

const UserList = () => {
  const [checkedUsers, setCheckedUsers] = useState([]);
  const { plans, plansOptions } = useContext(PlansContext);
  const { toastError, toastSuccess } = useContext(ToastContext);
  const { usersList, loadFlag, setLoadFlag } = useContext(AllUsersContext);

  const updateUsersList = async (data) => {
    const validData = getDataWithValidPlan(data, 'plan');

		try {
			const resp = await axiosInstance.put(`/update-users`, validData);

      if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`User list table has been updated`);
      }
		} catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }
			console.log('err', error)
		}
	};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: usersList || [],
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updateUsersList(values);
    },
  });

  const handleChangeChecked = (rowId) => {
    let checked = [...checkedUsers, rowId];

    if (checkedUsers.includes(rowId)) {
      checked = checked.filter(day => day !== rowId);
    } 
    setCheckedUsers(checked);
  };

  const { values, isValid, errors, dirty, handleSubmit, handleChange, setFieldValue } = formik;
  const tableData = getTableDataUserList({ values, plansOptions, setFieldValue, handleChange, handleChangeChecked });
  const getStatusClassName = (status) => styles[String(status).charAt(0).toLowerCase() + status.slice(1)];

  const tableRows = ({ row, index }) => {
    const data = tableData.map(item => {
      if (item.component) return <td key={item.title}>{item.component({ rowId: row._id, rowIndex: index })}</td>
      if (item.key === 'status') return <td key={item.title}><span className={getStatusClassName(row['status'])}>{row[item.key]}</span></td>

      return <td key={item.title}>{row[item.key]}</td>
    });

    return data
  }
    
	const deleteUsers = async () => {
		try {
			const resp = await axiosInstance.post(`/delete-users`, checkedUsers);

      if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Users has been deleted`);
      }
		} catch (error) {
			toastError(error);
			console.log('err', error)
		}
	};

	const blockUsers = async () => {
		try {
			const resp = await axiosInstance.put(`/block-users`, checkedUsers);

      if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Users has been blocked`);
      }
		} catch (error) {
			toastError(error);
			console.log('err', error)
		}
	};

	const unblockUsers = async () => {
		try {
			const resp = await axiosInstance.put(`/unblock-users`, checkedUsers);

      if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Users has been unblocked`);
      }
		} catch (error) {
			toastError(error);
			console.log('err', error)
		}
	};

  const filtersItems = [
    { icon: DeleteIcon, text: 'Delete', onClick: deleteUsers },
    { icon: BlockIcon, text: 'Block', onClick: blockUsers },
    { icon: UnblockIcon, text: 'Unblock', onClick: unblockUsers },
  ];

  const modifiedUsersList = useMemo(() => getModifiedUsersList(usersList, plans), [usersList, plans]);

  return (
    <FormikProvider value={formik}>
      <div className={styles.table_wrapper}>

        <TableFilters data={filtersItems} />

        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                {tableData.map(header => (<th key={header.title}>{header.title}</th>))}
              </tr>
            </thead>
            <tbody>
              {modifiedUsersList?.map((row, index) => (
                <tr key={row._id}>
                  {tableRows({row, index})}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ErrorMessage errors={errors} />

        <div className={styles.button_wrapper}>
          <Button
            type='submit'
            text='Save'
            disabled={!isValid || !dirty}
            onClick={handleSubmit}
          />
        </div>
        
      </div>
    </FormikProvider>
  );
}

export default UserList;

import Date from 'components/Date';

import styles from './styles.module.scss';

const FromToDate = ({
  nameTo,
  valueTo,
  onChange,
  nameFrom,
  valueFrom,
}) => (
  <div className={styles.date_wrapper}>
    <span> start </span>
    <Date
      name={nameFrom}
      value={valueFrom}
      onChange={onChange}
    />
    <span> end </span>
    <Date
      name={nameTo}
      value={valueTo}
      onChange={onChange}
    />
  </div>
);

export default FromToDate;

import { useContext } from 'react';
import { validationSchema } from './schema';
import axiosInstance from 'utils/axiosInstance';
import { useFormik, FormikProvider } from 'formik';
import { couponGenInitialValues } from 'constants';
import { resetRadioBtns } from 'helpers/generator';
import { PlansContext } from 'context/PlansContext';
import { ToastContext } from 'context/ToastContext';
import { CouponsContext } from 'context/CouponsContext';
import { getTableDataCouponGen } from 'helpers/getTablesData';
import { getModifiedDataCouponGenerator } from 'helpers/modifiedData';

import Header from './Header';
import Footer from './Footer';
import Button from 'components/Button';
import ErrorMessage from 'components/Table/ErrorMessage';

import styles from './styles.module.scss';

const Generator = () => {
  const { plansOptions } = useContext(PlansContext);
  const { loadFlag, setLoadFlag } = useContext(CouponsContext);
  const { toastError, toastSuccess } = useContext(ToastContext);

  const createNewCoupon = async (values) => {
    try {
      const resp = await axiosInstance.post(`/create-coupon`, values);

      if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`New coupon has been created`);
      }

      return resp;
    } catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }
			console.log('err', error)
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: couponGenInitialValues,
    onSubmit: async (values, { resetForm }) => {
      const validValues = getModifiedDataCouponGenerator(values);
      const resp = await createNewCoupon(validValues);

      if (resp?.data) {
        resetForm();
        // need resetRadioBtns because resetForm not working with input radio
        resetRadioBtns();
      }
    },
  });

  const { values, isValid, errors, dirty, setValues, handleSubmit, handleChange, setFieldValue } = formik;
  const tableData = getTableDataCouponGen(values, plansOptions, handleChange, setValues, setFieldValue);

  return (
    <FormikProvider value={formik}>
      <div className={styles.table_wrapper}>
        <div className={styles.table}>
          <Header data={tableData} />
          <Footer data={tableData} />
          <ErrorMessage errors={errors} />
        </div>
        <div className={styles.button_wrapper}>
          <Button
            type='submit'
            text='Create'
            onClick={handleSubmit}
            disabled={!isValid || !dirty}
          />
        </div>
      </div>
    </FormikProvider>
  );
}

export default Generator;

import { EMAIL_REGEX } from 'constants';
import { string, object, array } from 'yup'

export const validationSchema = array(
  object().shape({
    name: string().required('Name is required'),
    surname: string().required('Surname is required'),
    email: string().matches(EMAIL_REGEX, "Email is not valid").required('Email is required'),
  })
);

import styles from './styles.module.scss';

const Input = ({
  name,
  value,
  onChange,
  disabled,
  type='input',
  placeholder,
}) => (
  <input
    name={name}
    type={type}
    value={value}
    autoComplete="off"
    disabled={disabled}
    onChange={onChange}
    className={styles.input}
    placeholder={placeholder}
  />
);

export default Input;

import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { logOut } from 'helpers/logOut';
import { useAuth } from 'helpers/useAuth';
import axiosInstance from 'utils/axiosInstance';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [ user, setUser ] = useState(null);
  const [ loadFlag, setLoadFlag ] = useState(false);
  const token = useAuth();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      
      const fetchCoupons = async () => {
        try {
          const resp = await axiosInstance.post(`/get-user`, { email: decoded?.email });

          setUser(resp?.data);
        } catch (error) {
          logOut(setUser, navigate);
          navigate('/error');
        }
    };
    fetchCoupons();
    }
  }, [token, loadFlag, navigate]);

  return (
    <AuthContext.Provider value={{ user, setUser, loadFlag, setLoadFlag }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
import FooterCell from './FooterCell';

import styles from './styles.module.scss';

const TableFooter = ({ data }) => (
  <div className={styles.footer}>
    {data?.map(item => (
      <FooterCell key={item.title}>
        <div className={styles.input_wrapper}>
          {item.component}
        </div>
      </FooterCell>
    ))}
  </div>
);

export default TableFooter;
import { useMemo } from "react";
import cn from 'classnames';
import { getCardRows } from 'helpers/table';
import { getModifiedPlansData, getPlansDataWithUnits } from 'helpers/modifiedData';

import Button from '../Button';

import styles from './styles.module.scss';

const Card = ({ plan, plansUnits, userPlanId, planType, couponData, onClick }) => {
  const modifiedPlan = getModifiedPlansData(plan, planType, couponData);
  const planWithUnits = getPlansDataWithUnits(modifiedPlan, plansUnits);

  const requestData = {
    name: modifiedPlan.name,
    planId: modifiedPlan.planId,
    price: modifiedPlan.rows.price,
  };

  const { btnText, arePlansEquals} = useMemo(() => {
    const arePlansEquals = userPlanId === plan?.planId;
    const btnText = userPlanId < plan?.planId ? 'Upgrade' : userPlanId > plan?.planId ? 'Downgrade' : 'Current';

    return {
      btnText,
      arePlansEquals,
    }
  },[plan, userPlanId]);

  const { name, rows } = planWithUnits;
  const cardRows = getCardRows({ rows, className: styles.plan_item });
  const cardClassNames = cn(styles.card, { [styles.disabled]: arePlansEquals })

  return (
    <div className={cardClassNames}>
      <div className={styles.title}>
        {name}
      </div>
      {cardRows}
      <div className={styles.button_wrapper}>
        <Button
          text={btnText}
          disabled={arePlansEquals}
          onClick={() => onClick(requestData)}
        />
      </div>
    </div>
  )
};

export default Card;

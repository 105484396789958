import styles from './styles.module.scss';

const TableFilters = ({ data }) => (
  <div className={styles.filters}>
    {data.map(item => (
      <div className={styles.filters_item} key={item.text} onClick={item.onClick}>
        <div className={styles.icon}>
          <img src={item.icon} alt={item.text} />
        </div>
        <div className={styles.text}>
          {item.text}
        </div>
      </div>
    ))}
  </div>
);

export default TableFilters;

import { useContext } from 'react';
import { validationSchema } from './schema';
import axiosInstance from 'utils/axiosInstance';
import { PlansContext } from 'context/PlansContext';
import { ToastContext } from 'context/ToastContext';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { getPlansColumns, getPlansFirstColumn } from 'helpers/table';

import Input from 'components/Input'
import Button from 'components/Button';
import ErrorMessage from 'components/Table/ErrorMessage';

import AddIcon from 'assets/icons/add.svg';
import RemoveIcon from 'assets/icons/remove.svg';

import styles from './styles.module.scss';

const Plans = () => {
  const { toastError, toastSuccess } = useContext(ToastContext);
  const { plans, loadFlag, setLoadFlag } = useContext(PlansContext);

  const updatePlans = async (data) => {
		try {
			const resp = await axiosInstance.put(`/update-plans`, data);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Plans table has been updated`);
      }
		} catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }
			console.log('err', error)
		}
	};

  const addNewPlan = async () => {
		try {
			const resp = await axiosInstance.put(`/add-new-plan`);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Plan has been added`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const deletePlan = async (planId) => {
		try {
			const resp = await axiosInstance.put(`/delete-plan`, { planId });

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Plan has been deleted`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const addNewPlanRow = async () => {
		try {
			const resp = await axiosInstance.put(`/add-new-plan-row`);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Plan has been added`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const deletePlanRow = async (fieldName) => {
		try {
			const resp = await axiosInstance.put(`/delete-plan-row`, { fieldName });

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Plan row has been deleted`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: plans || {},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updatePlans(values);
    },
  });

  const { values, isValid, errors, dirty, handleSubmit, handleChange } = formik;

  const deleteRow = (key) => (
    <div className={styles.remove_row_wrapper} onClick={() => deletePlanRow(key)}>
      <img className={styles.icon_img} src={RemoveIcon} alt="Remove plan row" />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <div className={styles.table_wrapper}>
        <div className={styles.table}>
          <div className={styles.column_wrapper}>
            <div className={styles.remove_wrapper} />
            {getPlansFirstColumn(
              plans?.plansUnits,
              values,
              styles.title,
              styles.cell,
              deleteRow,
              styles.class_name_wrapper,
              handleChange
            )}
          </div>

          <FieldArray
            render={() => (
              <>
                {plans?.data?.map((onePlan, index) => (
                  <div key={onePlan.name + onePlan.planId}>
                    <div className={styles.remove_wrapper} onClick={() => deletePlan(onePlan._id)}>
                      <img className={styles.icon_img} src={RemoveIcon} alt="Remove plan" />
                    </div>
                    <div className={styles.active_column} >
                      <div className={styles.title}>
                      <Input name={`data.[${index}].name`} value={values?.data?.[index]?.name || ''} onChange={handleChange} />
                      </div>
                      <div className={styles.column_wrapper}>
                        {getPlansColumns(onePlan.rows, index, styles.cell, values, handleChange)}
                      </div>
                    </div>
                  </div>
                ))}
                <div className={styles.add_wrapper} onClick={addNewPlan}>
                  <img className={styles.icon_img} src={AddIcon} alt="Add plan" />
                  <div className={styles.add_text}>
                    Add plan
                  </div>
                </div>
              </>
            )}
          />
        </div>
        <ErrorMessage errors={errors} />
        <div className={styles.add_row_wrapper} onClick={addNewPlanRow}>
          <img className={styles.icon_img} src={AddIcon} alt="Add row" />
          <div className={styles.add_text}>
            Add row
          </div>
        </div>
        <div className={styles.button_wrapper}>
          <Button
            text='Save'
            type='submit'
            disabled={!isValid || !dirty}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </FormikProvider>
  )
};

export default Plans;

import { useState, useEffect, useContext } from "react";
import { logOut } from 'helpers/logOut';
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { useIsAdmin } from "helpers/useIsAdmin";
import { AuthContext } from "context/AuthContext";
import { PlansContext } from "context/PlansContext";
import { ToastContext } from "context/ToastContext";

import Card from 'components/Card';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import Button from 'components/Button';

import styles from './styles.module.scss';

const UserPage = () => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const { plans } = useContext(PlansContext);
  const { toastError, toastSuccess } = useContext(ToastContext);
  const clonedPlans = plans?.data && JSON.parse(JSON.stringify(plans.data));
  const { user, setUser, loadFlag, setLoadFlag } = useContext(AuthContext);

  const [couponCode, setCouponCode] = useState('');
  const [couponData, setCouponData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [lastClickedPlan, setLastClickedPlan] = useState(false);
  const [planType, setPlanType] = useState(false);

  const onClickPlanBtn = (plan) => {
    setLastClickedPlan(plan);
    setChangePlanModalOpen(true);
  }

  const updateUserPlan = async (data) => {
    const validData = [
      {
        ...data?.user,
        plan: data?.plan?.planId,
      }
    ];

		try {
			const resp = await axiosInstance.put(`/update-users`, validData);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`Your plan has been upgraded`);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

	const apllyCoupon = async () => {
		try {
			const resp = await axiosInstance.post(`/check-coupon`, { couponCode: couponCode, email: user?.email });

      if (resp.data) {
        setCouponData(resp.data)
        toastSuccess(`Coupon "${couponCode}" has been applied`)
      }

		} catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }

			console.log('err', error)
		}
	};

	const deleteAccount = async () => {
		try {
			const resp = await axiosInstance.post(`/delete-users`, [user._id]);

      if (resp) {
        toastSuccess(`Your account has been deleted`);
        logOut(setUser, navigate);
      }
		} catch (error) {
			console.log('err', error)
		}
	};

  const makePayment = async () => {
    const updateUserPlanObj = {
      user,
      plan: lastClickedPlan,
    }

    // check if clicked plan is first item in plans array and have smallest id
    if (lastClickedPlan.planId === plans?.data?.[0]?.planId) {
      updateUserPlan(updateUserPlanObj);
      setChangePlanModalOpen(false);
      return;
    }

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
    const resp = await axiosInstance.post(`/create-checkout-session`, updateUserPlanObj);
    const result = await stripe.redirectToCheckout({
      sessionId: resp?.data?.id,
    });

    if (result.error) console.log('err', result.error)
  };

  // redirect if user role is admin
  useEffect(() => {
    if (isAdmin) navigate('/admin');
  }, [isAdmin, navigate]);

  return (
    <div className={styles.page}>
      <div className={styles.switch_wrapper}>
        <Switch
          checked={planType}
          textAfter='Annual'
          textBefore='Monthly'
          handleChange={setPlanType}
        />
      </div>
      <div className={styles.cards_wrapper}>
        {clonedPlans?.map(plan => (
          <Card
            plan={plan}
            key={plan.name}
            planType={planType}
            userPlanId={user?.plan}
            couponData={couponData}
            onClick={onClickPlanBtn}
            plansUnits={plans?.plansUnits}
          />
        ))}
      </div>
      <div className={styles.coupon}>
        <div className={styles.input_wrapper}>
          <Input placeholder='Coupon Code' onChange={(e) => setCouponCode(e.target.value)}/>
        </div>
        <Button
          text="Apply"
          onClick={apllyCoupon}
        />
      </div>

      <div className={styles.bottom_section}>
        <div className={styles.bottom_section_title}>
          Delete your account
        </div>
        <div className={styles.bottom_section_text}>
          All your personal information and assets will be removed. This can't be undone.
        </div>
        <Button
          view='danger'
          text="Delete Account"
          onClick={() => setDeleteModalOpen(true)}
        />
      </div>

      <Modal
        modalIsOpen={deleteModalOpen}
        onClickAccept={deleteAccount}
        title='Are you sure, delete your account?'
        onClickClose={() => setDeleteModalOpen(false)}
      />
      <Modal
        onClickAccept={makePayment}
        modalIsOpen={changePlanModalOpen}
        title='Are you sure, change your plan?'
        onClickClose={() => setChangePlanModalOpen(false)}
      />
    </div>
  )
};

export default UserPage;
import React, { useMemo, useEffect } from 'react';
import { routesList } from 'constants';
import { useIsAdmin } from "helpers/useIsAdmin";
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';

const AdminPage = () => {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { admin, userManagement } = routesList;

  const linkObj = useMemo(() => 
    pathname === admin
      ? { path: userManagement, title: 'User management' }
      : { path: admin, title: 'Plans and Coupon generator'}
      ,[pathname, admin, userManagement]
  );

  useEffect(() => {
    if (!isAdmin) navigate('/');
  }, [isAdmin, navigate]);

  return (
    <>
      <Link to={linkObj.path}>Go to {linkObj.title}</Link>
      <Outlet />
    </>
  )
};

export default AdminPage;

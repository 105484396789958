import {
  statuses,
  userTypeValues,
  subscriptionTypes,
  discountItemsIcons,
  discountItemsValues,
} from 'constants';
import { makeCoupon } from 'helpers/table';

export const getModifiedUsersList = (data, plans) => {
  const newData = data.map(item => {
    const modifiedPlan = plans?.data?.find(plan => plan.planId === item.plan)?.name;
    const modifiedStatus = statuses[item.status];
    const modifiedRegDate = new Date(item.createdAt).toLocaleDateString();
    const modifiedLastLogin = new Date(item.updatedAt).toLocaleDateString();

    return {
      ...item,
      plan: modifiedPlan,
      status: modifiedStatus,
      createdAt: modifiedRegDate,
      updatedAt: modifiedLastLogin,
    }
  })

  return newData;
};

export const getModifiedDataCouponGenerator = (data) => {
  const modifiedUserType = Number(data.userType);
  // const modifiedCouponType = Number(data.couponType);
  const modifiedDiscountType = Number(data.discountType);
  // const modifiedMinLimitType = Number(data.minLimitType);
  const modifiedDiscountAmount = Number(data.discountAmount);
  const modifiedMinLimitPlanId = Number(data.minLimitPlanId.value);
  const couponCode = makeCoupon();
  
  return {
    ...data,
    couponCode,
    userType: modifiedUserType,
    // couponType: modifiedCouponType,
    discountType: modifiedDiscountType,
    // minLimitType: modifiedMinLimitType,
    discountAmount: modifiedDiscountAmount,
    minLimitPlanId: modifiedMinLimitPlanId,
  }
};

export const getModifiedActiveCouponsList = (data, plans) => {
  const newData = data.map(item => {
    const {
      _id,
      email,
      userType,
      couponName,
      couponCode,
      discountType,
      minLimitPlanId,
      // minLimitType,
      discountAmount,
      validityDateEnd,
      validityDateStart
    } = item;

    const modifiedValidityDateEnd = new Date(validityDateEnd).toLocaleDateString();
    const modifiedDiscount = `${discountAmount} ${discountItemsIcons[discountType]}`;
    const modifiedValidityDateStart = new Date(validityDateStart).toLocaleDateString();
    const modifiedUser = email !== '' ? email : userTypeValues[userType];
    const modifiedMinLimit = plans?.data?.find(plan => plan.planId === minLimitPlanId)?.name;
    
    return {
      _id,
      couponCode,
      couponName,
      user: modifiedUser,
      minLimit: modifiedMinLimit,
      // usageLimit: minLimitType,
      discount: modifiedDiscount,
      validityDateEnd: modifiedValidityDateEnd,
      validityDateStart: modifiedValidityDateStart,
    }
  })

  return newData;
};

export const getDataWithValidPlan = (data, key) => {
  const newData = data.map(item => {
    const plan = item[key].value;
    
    return {
      ...item,
      plan,
    }
  })

  return newData;
};

export const getModifiedPlansData = (plan, planType, couponData) => {
  const isMinLimitPass = plan?.planId >= couponData?.minLimitPlanId;
  const clonePlan = plan && JSON.parse(JSON.stringify(plan));
  const isDiscountPercent = Number(couponData?.discountType) === discountItemsValues?.percent;
  const isDiscountUsd = Number(couponData?.discountType) === discountItemsValues?.usd;
  const subscriptionType = subscriptionTypes[Number(planType)];
  const { rows } = clonePlan;
  // Need rowsClone
  const rowsClone = {...rows};

  for (const key in rowsClone) {
    // need for discount
    if (key === 'price' && isMinLimitPass) {
      if (isDiscountPercent) {
        const validPrice = parseFloat(rowsClone['price']?.[subscriptionType]);
        rowsClone['price'][subscriptionType] = validPrice - (validPrice * (couponData?.discountAmount / 100))
      } else if (isDiscountUsd) {
        const validPrice = parseFloat(rowsClone['price']?.[subscriptionType]);
        rowsClone['price'][subscriptionType] = validPrice - couponData?.discountAmount;
        // If Price < 0 set Price = 0
        if (rowsClone['price']?.[subscriptionType] < 0) rowsClone['price'][subscriptionType] = 0;
      }
    }
    rowsClone[key] = key !== 'price' ? rowsClone[key] : rowsClone[key]?.[subscriptionType];
  }

  // need for placed price to end of rows
  const { price, ...rest } = rowsClone;

  return {...clonePlan, rows: { ...rest, price }};
};

export const getPlansDataWithUnits = (plan, plansUnits) => {
  const { rows } = plan;
  // Need rowsClone
  const rowsClone = {...rows};

  // replace values to values with units
  for (const key in rowsClone) {
    rowsClone[key] = rowsClone[key] + ' ' + plansUnits[key];
  }

  return {...plan, rows: rowsClone};
};

import { useState, useEffect, createContext } from 'react';
import axiosInstance from 'utils/axiosInstance';

const PlansContext = createContext();

const PlansProvider = ({ children, isPublicPages }) => {
  const [ plans, setPlans ] = useState([]);
  const [ loadFlag, setLoadFlag ] = useState(false);

  const plansOptions = plans?.data?.map(plan => ({
    label: plan.name,
    value: plan.planId
  }));

  useEffect(() => {
    const fetchPlans = async () => {
      if (!isPublicPages) {
        try {
          const resp = await axiosInstance.post(`/plans`);
    
          setPlans(resp?.data);
        } catch (error) {
          console.log('err', error)
        }
      }
    };
    fetchPlans();
  }, [isPublicPages, loadFlag]);
  
  return (
    <PlansContext.Provider value={{ plans, plansOptions, loadFlag, setLoadFlag }}>
      {children}
    </PlansContext.Provider>
  );
}

export { PlansProvider, PlansContext };

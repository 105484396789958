import Input from 'components/Input';

import styles from './styles.module.scss';

const HeaderCell = ({ data }) => {
  const isActionDataExist = data?.titleActionData.length > 0;

  return (
    <div className={styles.header_cell}>
      <div className={styles.title}>
        {data.title}
      </div>

      {isActionDataExist && (
        <div className={styles.active_area}>
          {data?.titleActionData?.map(item => (
            <div className={styles.input_wrapper} key={item.value}>
              <span className={styles.item_text}>
                {item.text}
              </span>
              <Input
                type="radio"
                name={item.name}
                value={item.value}
                onChange={item.onChange}
              />
            </div>
          ))}
			  </div>
      )}
      
    </div>
  );
}

export default HeaderCell;
import cn from 'classnames';

import styles from './styles.module.scss';

const Button = ({
  logo,
  text,
  onClick,
  disabled,
  className,
  type='button',
  view = 'primary',
}) => {
  const buttonClannames = cn(styles.button, className, styles[`view_${view}`], {
    [styles.disabled]: disabled,
  });

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button type={type} disabled={disabled} className={buttonClannames} onClick={onClickHandler}>
      {logo && (
        <div className={styles.logo_wrapper}>
         <img alt="logo" src={logo} className={styles.logo}/>
       </div>
      )}

      {text}
    </button>
  );
};

export default Button;

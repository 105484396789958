import { MANY_EMAILS_REGEX, userTypeKeys } from 'constants';
import { date, string, object, number } from 'yup'

export const validationSchema = object().shape({
  couponName: string().required('Coupone Name is required'),
  discountType: string().required('Discount Type is required'),
  discountAmount: number()
    .typeError('Discount Amount must be a number')
    .positive('Discount Amount must be a positive value')
    .required('Discount Amount is required'),
  validityDateStart: date().required('Start Date is required'),
  validityDateEnd: date().required('End Date is required'),
  minLimitPlanId: object().required('Min Limit is required'),
  userType: string().required('User Type is required'),
  email: string().when('userType', {
    is: (value) => Number(value) === userTypeKeys.specific,
    then: (schema) => schema.matches(MANY_EMAILS_REGEX, "Email is not valid").required('Email is required'),
  }),
})

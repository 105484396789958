import axios from 'axios';
import { roleValues } from 'constants';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import Button from 'components/Button';

import GoogleIcon from 'assets/icons/google.png';

import styles from './styles.module.scss';

const LoginPage = () => {
  const navigate = useNavigate()

  let defaultRedirect = '/';

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (data) => {
      try {
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/google-auth`, {
          code: data.code,
        });

        localStorage.setItem('token', resp?.data?.token);

        defaultRedirect = defaultRedirect + roleValues[resp.data.role] || 'user';
        navigate(defaultRedirect);
      } catch (error) {
        console.log('err', error)
      }
    },
    onError: err => console.log('err_google', err)
  });

  return (
    <div className={styles.page}>
      <div className={styles.title}>Welcome</div>
      <div className={styles.subtitle}>Please login to continue</div>
        <Button
          text='Google'
          view='secondary'
          onClick={login}
          logo={GoogleIcon}
          className={styles.login_btn}
        />
    </div>
  )
};

export default LoginPage;
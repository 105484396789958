import { useNavigate } from "react-router-dom";

import Button from 'components/Button';

import styles from './styles.module.scss';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <span className={styles.text}>
        Ooooops!!!! Something went wrong!
        Please try later!
      </span>

      <Button
        text="Go to login page"
        onClick={() => navigate('/login')}
      />
    </div>
  )
};

export default ErrorPage;

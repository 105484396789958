import { createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const { error: toastError, success: toastSuccess } = toast;
  
  return (
    <ToastContext.Provider value={{ toastError, toastSuccess }}>
      {children}
      <ToastContainer theme="colored" style={{ width: "fit-content" }} />
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext };

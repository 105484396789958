import { useContext } from 'react';
import { validationSchema } from './schema';
import axiosInstance from 'utils/axiosInstance';
import { userGenInitialValues } from 'constants';
import { useFormik, FormikProvider } from 'formik';
import { PlansContext } from 'context/PlansContext';
import { ToastContext } from 'context/ToastContext';
import { AllUsersContext } from 'context/AllUsersContext';
import { getTableDataUserGen } from 'helpers/getTablesData';

import Header from './Header';
import Footer from './Footer';
import Button from 'components/Button';
import ErrorMessage from 'components/Table/ErrorMessage';

import styles from './styles.module.scss';

const Generator = () => {
  const { plansOptions } = useContext(PlansContext);
  const { toastError, toastSuccess } = useContext(ToastContext);
  const { loadFlag, setLoadFlag } = useContext(AllUsersContext);

  const getValidValues = (values) => ({
    ...values,
    plan: values?.plan?.value,
  });

  const createNewUser = async (values) => {
    try {
      const resp = await axiosInstance.post(`/create-user`, values);

			if (resp) {
        setLoadFlag(!loadFlag);
        toastSuccess(`New user has been created`);
      }

      return resp;
    } catch (error) {
      if (error?.response?.status === 403) {
        toastError(error?.response?.data?.err)
      }
      console.log('err', error)
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: userGenInitialValues,
    onSubmit: async (values, { resetForm }) => {
      const validValues = getValidValues(values);
      const resp = await createNewUser(validValues);

      if (resp?.data?.user) {
        resetForm();
      }
    },
  });

  const { values, isValid, errors, dirty, handleSubmit, handleChange, setFieldValue } = formik;
  const tableData = getTableDataUserGen(values, plansOptions, handleChange, setFieldValue);

  return (
    <FormikProvider value={formik}>
      <div className={styles.table_wrapper}>
        <div className={styles.table}>
          <Header data={tableData} />
          <Footer data={tableData} />
          <ErrorMessage errors={errors} />
        </div>
        <div className={styles.button_wrapper}>
          <Button
            type='submit'
            text='Create'
            disabled={!isValid || !dirty}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </FormikProvider>
  );
};

export default Generator;

export * from './regex';

export const couponCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const couponMaxLength = 8;

export const role = {
  user: 2,
  admin: 1,
};

export const roleValues = {
  1: 'admin',
  2: 'user',
};

export const routesList = {
  root: '/',
  admin: '/admin',
  error: '/error',
  login: '/login',
  cancel: '/cancel',
  success: '/success',
  userManagement: 'user-management',
};

// export const couponTypeValues = {
//   coupon: 1,
//   campaing: 2,
// };

export const discountItemsValues = {
  percent: 1,
  usd: 2,
};

export const discountItemsIcons = {
  1: '%',
  2: '$',
};

// export const minLimitsValues = {
//   amount: 1,
//   planType: 2,
// };

export const userTypeKeys = {
  all: 1,
  new: 2,
  current: 3,
  specific: 4,
};

export const userTypeValues = {
  [userTypeKeys.all]: 'All',
  [userTypeKeys.new]: 'New',
  [userTypeKeys.current]: 'Current',
  [userTypeKeys.specific]: 'Specific',
};

export const statuses = {
  1: 'Active',
  2: 'Inactive',
};

export const couponGenInitialValues = {
  email: '',
  couponName: '',
  userType: null,
  // couponType: null,
  discountType: null,
  // minLimitType: null,
  discountAmount: '',
  validityDateEnd: '',
  minLimitPlanId: null,
  validityDateStart: '',
};

export const userGenInitialValues = {
  name: '',
  email: '',
  plan: null,
  surname: '',
};

export const subscriptionTypes = {
  0: 'month',
  1: 'year',
};

import styles from './styles.module.scss';

const HeaderCell = ({ data }) => (
  <div className={styles.header_cell}>
    <div className={styles.title}>
      {data.title}
    </div>
  </div>
);

export default HeaderCell;

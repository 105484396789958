import { getErrorMessage } from 'helpers/table';

import styles from './styles.module.scss';

const ErrorMessage = ({ errors }) => {
  const errorMessage = getErrorMessage(errors);

  return (
    <div className={styles.error_message}>
      {errorMessage}
    </div>
  )
};

export default ErrorMessage;

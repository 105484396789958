import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import SuccessIcon from 'assets/icons/success.svg';

import styles from './styles.module.scss';

const Success = () => {
  const navigate = useNavigate();

   return (
    <div className={styles.success}>
      <div className={styles.icon}>
        <img src={SuccessIcon} alt='success icon' />
      </div>
      <div className={styles.text}>
        Thanks for your order!
      </div>

      <div className={styles.button_wrapper}>
        <Button
          view='secondary'
          text='Go to profile page'
          onClick={() => navigate('/')}
        />
      </div>
    </div>
   )
};

export default Success;
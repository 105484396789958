import HeaderCell from './HeaderCell';

import styles from './styles.module.scss';

const TableHeader = ({ data }) => (
  <div className={styles.header}>
    {data?.map((cellData, index) => <HeaderCell data={cellData} key={cellData.title + index} />)}
  </div>
);

export default TableHeader;
// import cn from 'classnames';
import Switch from "react-switch";

import styles from './styles.module.scss';

const SwitchComponent = ({
  checked,
  textAfter,
  textBefore,
  handleChange,
}) => {
  // const buttonClannames = cn(styles.button, styles[`type_${view}`], {
  //   [styles.disabled]: disabled,
  // });

  // const onClickHandler = () => {
  //   if (onClick) {
  //     onClick();
  //   }
  // };

  return (
    <div className={styles.wrapper}>
      <span>{textBefore}</span>
      <Switch
        onColor='#4285f4'
        offColor='#34a853'
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={handleChange}
        className={styles.switch}
      />
      <span>{textAfter}</span>
  </div>
  );
};

export default SwitchComponent;

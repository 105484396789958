import { Field } from 'formik';
import moment from 'moment';
import { discountItemsValues, userTypeKeys } from 'constants';

import Select from 'react-select';
import Date from 'components/Date';
import Input from 'components/Input';
import FromToDate from 'components/FromToDate';
import TableActiveSection from 'components/Table/TableActiveSection';

const getFormatedDate = (date) => moment(date).format("YYYY-MM-DD[T]HH:mm");
const getPlanValues = (planId, options) => options?.find(item => item.value === planId);

export const getTableDataCouponGen = (values, plansOptions, handleChange, setValues, setFieldValue) => {
  const handleChangeUserType = (e) => setValues({
		...values,
		email: '',
		userType: e.target.value,
	});

  const tableData = [
    { title: 'Coupon', titleActionData: [
      // { text: 'Coupon', value: couponTypeValues.coupon, name: 'couponType', onChange: handleChange },
      // { text: 'Campaing', value: couponTypeValues.campaing, name: 'couponType', onChange: handleChange },
    ],
    component: <Input name='couponName' value={values['couponName']} onChange={handleChange} />,
    },
    { title: 'Discount', titleActionData: [
      { text: '%', value: discountItemsValues.percent, name: 'discountType', onChange: handleChange },
      { text: '$', value: discountItemsValues.usd, name: 'discountType', onChange: handleChange },
    ],
    component: <Input name='discountAmount' value={values['discountAmount']} onChange={handleChange} />,
    },
    { title: 'Validity Date', titleActionData: [],
      component: (
        <FromToDate
          onChange={handleChange}
          nameTo='validityDateEnd'
          nameFrom='validityDateStart'
          valueTo={values['validityDateEnd']}
          valueFrom={values['validityDateStart']}
        />
      ),
    },
    { title: 'Min Limit', titleActionData: [
    //   { text: 'amount', value: minLimitsValues.amount, name: 'minLimitType', onChange: (e) => setValues({
    //     ...values,
    //     minLimitType: e.target.value,
    //     minLimitAmount: '',
    // })},
      // { text: 'plan type', value: minLimitsValues.planType, name: 'minLimitType', onChange: handleChange },
    ],
    component:
      // Number(values['minLimitType']) === minLimitsValues.amount
      // ? <Input name='minLimitAmount' value={values['minLimitAmount']} onChange={handleChange}/>
      // : 
      <Field
        as={Select}
        className='dropdown'
        name='minLimitPlanId'
        options={plansOptions}
        value={values['minLimitPlanId']}
        onChange={(option) => setFieldValue('minLimitPlanId', option)}
      />,
    },
    { title: '', titleActionData: [
      { text: 'All', value: userTypeKeys.all, name: 'userType', onChange: handleChangeUserType },
      { text: 'New', value: userTypeKeys.new, name: 'userType', onChange: handleChangeUserType },
      { text: 'Current', value: userTypeKeys.current, name: 'userType', onChange: handleChangeUserType },
      { text: 'Specific', value: userTypeKeys.specific, name: 'userType', onChange: handleChangeUserType },
    ],
    component: <Input disabled={Number(values['userType']) !== userTypeKeys.specific} name='email' value={values['email']} onChange={handleChange} />,
    },
  ];

  return tableData;
};

export const getTableDataUserGen = (values, plansOptions, handleChange, setFieldValue) => {
  const tableData = [
    {title: 'User Name', component: <Input name='name' value={values['name']} onChange={handleChange} />},
    {title: 'User Surname', component: <Input name='surname' value={values['surname']} onChange={handleChange} />},
    {title: 'Email', component: <Input name='email' value={values['email']} onChange={handleChange} />},
    {
      title: 'Plan',
      component:
        <Field
          as={Select}
          name='plan'
          className='dropdown'
          options={plansOptions}
          value={values['plan']}
          onChange={(option) => setFieldValue('plan', option)}
        />
    },
  ];

  return tableData;
};

export const getTableDataActiveCoupons = ({
  values,
  onClickCopy,
  couponsList,
  plansOptions,
  onClickShare,
  handleChange,
  setFieldValue,
  handleChangeChecked,
}) => {
  const tableData = [
    {title: '', component: ({ rowId }) => <Input type='checkbox' onChange={() => handleChangeChecked(rowId)} />},
    {title: 'Coupon Name', component: ({ rowIndex }) => (
      <Input
        onChange={handleChange}
        name={`[${rowIndex}].couponName`}
        value={values?.[rowIndex]?.couponName || ''}
      />)
    },
    {title: 'Discount', key: 'discount'},
    {title: 'Start date', component: ({ rowIndex }) => (
      <Date
        onChange={handleChange}
        name={`[${rowIndex}].validityDateStart`}
        value={getFormatedDate(values?.[rowIndex]?.validityDateStart)}
      />)
    },
    {title: 'End date', component: ({ rowIndex }) => (
      <Date
        onChange={handleChange}
        name={`[${rowIndex}].validityDateEnd`}
        value={getFormatedDate(values?.[rowIndex]?.validityDateEnd)}
      />)
    },
    {title: 'Min Limit', component: ({ rowIndex }) =>
      <Field
        as={Select}
        className='dropdown'
        options={plansOptions}
        name={`[${rowIndex}].minLimitPlanId`}
        value={getPlanValues(values?.[rowIndex]?.minLimitPlanId, plansOptions)}
        onChange={(option) => setFieldValue(`[${rowIndex}].minLimitPlanId`, option)}
      />
    },
    // {title: 'Validity Date', key: 'validityDate'},
    // {title: '№ of Usage', key: 'numberUsage'},
    // {title: 'Usage Limit', key: 'usageLimit'},
    {title: 'User', key: 'user'},
    {title: 'Coupon Code', key: 'couponCode'},
    {title: '', component: ({ rowId }) => (
      <TableActiveSection
        rowId={rowId}
        couponsList={couponsList}
        onClickCopy={onClickCopy}
        onClickShare={onClickShare}
      />
    )},
  ];
  return tableData;
};

export const getTableDataUserList = ({values, plansOptions, setFieldValue, handleChange, handleChangeChecked}) => {
  const data = [
    {title: '', component: ({ rowId }) => <Input type='checkbox' onChange={() => handleChangeChecked(rowId)}/>},
    {title: 'Name', component: ({ rowIndex }) => (
      <Input
        onChange={handleChange}
        name={`[${rowIndex}].name`}
        value={values?.[rowIndex]?.name || ''}
      />)
    },
    {title: 'Surname', component: ({ rowIndex }) => (
      <Input
        onChange={handleChange}
        name={`[${rowIndex}].surname`}
        value={values?.[rowIndex]?.surname || ''}
      />)
    },
    {title: 'Email', component: ({ rowIndex }) => (
      <Input
        onChange={handleChange}
        name={`[${rowIndex}].email`}
        value={values?.[rowIndex]?.email || ''}
      />)
    },
    {title: 'Plan', component: ({ rowIndex }) =>
      <Field
        as={Select}
        className='dropdown'
        options={plansOptions}
        name={`[${rowIndex}].plan`}
        value={getPlanValues(values?.[rowIndex]?.plan, plansOptions)}
        onChange={(option) => setFieldValue(`[${rowIndex}].plan`, option)}
      />
    },
    {title: 'Subscription Status', key: 'status'},
    {title: 'Registration Date', key: 'createdAt'},
    {title: 'Last Login', key: 'updatedAt'},
  ];

  return data;
};

import React from 'react';

import CopyIcon from 'assets/icons/copy.svg';
import ShareIcon from 'assets/icons/share.svg';

import styles from './styles.module.scss';

const TableActiveSection = ({ rowId, onClickCopy, onClickShare, couponsList }) => (
  <div className={styles.active_icons}>
    <div className={styles.icon} onClick={() => onClickCopy(rowId, couponsList)}>
      <img src={CopyIcon} alt='Copy' />
    </div>
    <div className={styles.icon} onClick={() => onClickShare(rowId, couponsList)}>
      <img src={ShareIcon} alt='Share' />
    </div>
  </div>
);

export default TableActiveSection;

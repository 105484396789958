const Date = ({
  name,
  value,
  onChange,
}) => (
  <input
    name={name}
    value={value}
    onChange={onChange}
    type="datetime-local"
    aria-label="Date and time"
  />
);

export default Date;

import React from 'react';

import styles from './styles.module.scss';

const Section = ({ title, table }) => (
  <div className={styles.section}>
    <div className={styles.title}>
      {title}
    </div>
    <div className={styles.table}>
      {table}
    </div>
  </div>
);

export default Section;
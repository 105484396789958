import React, { useMemo } from 'react';
import { routesList } from 'constants';
import { AuthProvider } from "context/AuthContext";
import { PlansProvider } from "context/PlansContext";
import { ToastProvider } from "context/ToastContext";
import PublicRoutes from 'helpers/routes/publicRoutes';
import PrivatRoutes from 'helpers/routes/privateRoutes';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import ErrorPage from 'pages/404';
import UserPage from 'pages/User';
import LoginPage from 'pages/Login';
import AdminPage from 'pages/Admin';
import Header from 'components/Header';
import Cancel from 'pages/payments/Cancel';
import Success from 'pages/payments/Success';
import UserManagement from 'pages/Admin/UserManagement';
import PlanCuponGerenerator from 'pages/Admin/PlanCouponGenerator';

const App = () => {
  const { root, admin, error, login, cancel, success, userManagement } = routesList;
  const { pathname } = useLocation();
  const isPublicPages = useMemo(() => pathname === '/login' || pathname === '/error', [pathname])

  return (
    <AuthProvider>
        <div className="App">
          <div className="container">
            <PlansProvider isPublicPages={isPublicPages} >
              <ToastProvider >

                {!isPublicPages && <Header />}
                <Routes>
                  <Route element={<PrivatRoutes />}>
                    <Route path={success} element={<Success />} />
                    <Route path={cancel} element={<Cancel />} />
                    <Route path={root} element={<UserPage />} />
                    <Route path={admin} element={<AdminPage />}>
                      <Route index element={<PlanCuponGerenerator />} />
                      <Route path={userManagement} element={<UserManagement />} />
                      <Route
                        path="*"
                        element={<Navigate to={admin} replace />}
                      />
                    </Route>
                  </Route>
                  <Route element={<PublicRoutes />}>
                    <Route path={login} element={<LoginPage />} />
                    <Route path={error} element={<ErrorPage />} />
                  </Route>
                  <Route
                    path="*"
                    element={<Navigate to={root} replace />}
                  />
                </Routes>
              
              </ToastProvider >
            </PlansProvider>
          </div>
        </div>
    </AuthProvider>
  );
}

export default App;

import { useMemo, useContext } from "react";
import { logOut } from 'helpers/logOut';
import { AuthContext } from "context/AuthContext";
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import Logo from 'assets/icons/main_logo.png';

import styles from './styles.module.scss';

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  
  const userName = useMemo(() => user?.name + ' ' + user?.surname || '', [user])

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logo_link}>
        <img alt="color with you logo" src={Logo} className={styles.logo}/>
      </Link>
      <div className={styles.right_section}>
        <div className={styles.username}>{userName}</div>
        <Button
          text='Logout'
          view='secondary'
          onClick={() => logOut(setUser, navigate)}
        />
      </div>
    </div>
  )
};

export default Header;
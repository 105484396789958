import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import CancelIcon from 'assets/icons/cancel.svg';

import styles from './styles.module.scss';

const Cancel = () => {
  const navigate = useNavigate();

   return (
    <div className={styles.cancel}>
      <div className={styles.icon}>
        <img src={CancelIcon} alt='success icon' />
      </div>
      <div className={styles.text}>
        Oops! Something went wrong! <br/>
        Please try again later!
      </div>

      <div className={styles.button_wrapper}>
        <Button
          view='secondary'
          text='Go to profile page'
          onClick={() => navigate('/')}
        />
      </div>
    </div>
   )
};

export default Cancel;
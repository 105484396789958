import { useState, useEffect, createContext } from 'react';
import axiosInstance from 'utils/axiosInstance';

const AllUsersContext = createContext();

const AllUsersProvider = ({ children }) => {
  const [ usersList, setUsersList ] = useState([]);
  const [ loadFlag, setLoadFlag ] = useState(false);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const resp = await axiosInstance.get(`/get-all-users`);

        setUsersList(resp?.data?.users);
      } catch (error) {
        console.log('err', error)
      }
    };
    fetchAllUsers();
  }, [loadFlag]);
  
  return (
    <AllUsersContext.Provider value={{ usersList, loadFlag, setLoadFlag }}>
      {children}
    </AllUsersContext.Provider>
  );
}

export { AllUsersProvider, AllUsersContext };

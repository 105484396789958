import Modal from 'react-modal';
import Button from 'components/Button';

import styles from './styles.module.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const ModalComponent = ({
  title,
  modalIsOpen,
  onClickClose,
  onClickAccept,
}) => {

  return (
    <>
    <Modal
      style={customStyles}
      isOpen={modalIsOpen}
      onRequestClose={onClickClose}
    >
      <div className={styles.modal_content_wrapper}>
        <h2>{title}</h2>

        <div className={styles.button_wrapper}>
          <Button onClick={onClickAccept} text='Yes' />
          <Button onClick={onClickClose} text='No' />
        </div>
      </div>
    </Modal>
    </>
  );
};

export default ModalComponent;

import { string, object, array, number } from 'yup'

export const validationSchema = object().shape({
  data: array(
    object().shape({
      name: string().required('Plan Name is required'),
      rows: object().shape({
        price: object().shape({
          month: number()
            .min(0, 'Month Price must be a positive value')
            .typeError('Month Price must be a number')
            .required('Month Price is required'),
          year: number()
            .min(0, 'Year Price must be a positive value')
            .typeError('Year Price must be a number')
            .required('Year Price is required'),
        })
      })
      .test(
        'is-each-row-value-not-empty',
        'Plan Value is required',
        planObj => Object.values(planObj).every(value => !!value)
      ),
    })
  ),
  plansUnits: object()
    .test(
      'is-each-value-not-empty',
      'Plan Unit Name is required',
      unitsObj => Object.values(unitsObj).every(value => !!value)
    ),
})


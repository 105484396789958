import { useState, useEffect, createContext } from 'react';
import axiosInstance from 'utils/axiosInstance';

const CouponsContext = createContext();

const CouponsProvider = ({ children }) => {
  const [ couponsList, setCouponsList] = useState([]);
  const [ loadFlag, setLoadFlag ] = useState(false);

  useEffect(() => {
    const fetchCoupons = async () => {
        try {
          const resp = await axiosInstance.get(`/get-coupons`);

          setCouponsList(resp?.data);
        } catch (error) {
          console.log('err', error)
        }
    };
    fetchCoupons();
  }, [loadFlag]);
  
  return (
    <CouponsContext.Provider value={{ couponsList, loadFlag, setLoadFlag }}>
      {children}
    </CouponsContext.Provider>
  );
}

export { CouponsProvider, CouponsContext };

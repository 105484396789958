import { AllUsersProvider } from "context/AllUsersContext";

import Section from 'components/Section';
import UserList from './tables/UserList';
import Generator from './tables/Generator';

import styles from './styles.module.scss';

const UserManagement = () => (
  <div className={styles.page}>
    <AllUsersProvider>
      <Section 
        title='User Generator'
        table={<Generator />}
      />

      <Section 
        title='User List'
        table={<UserList />}
      />
    </AllUsersProvider>
  </div>
);

export default UserManagement;
